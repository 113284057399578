import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { buildLocalizedPath } from "@utils/functions"
import { Container, LanguageSwitcher } from "@components"
import {
  LogoLink,
  MenuIcon,
  MobileMenu,
  StyledHeaderContainer,
  StyledHeader,
  StyledLanguage,
  StyledLanguageMobile,
  StyledLink,
  StyledNavigation,
} from "./style"
import Logo from "@assets/images/omega_servis_logo.svg"
import Icon from "@assets/images/menu.svg"

const Header = ({ locale }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false)

  return (
    <StyledHeaderContainer>
      <Container>
        <StyledHeader>
          <LogoLink to={buildLocalizedPath("index", locale)}>
            <img src={Logo} alt="Omega servis logo" width={150} />
          </LogoLink>
          <StyledNavigation>
            <StyledLink
              to={buildLocalizedPath("services", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.services" />
            </StyledLink>
            <StyledLink
              to={buildLocalizedPath("about", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.about" />
            </StyledLink>
            <StyledLink
              to={buildLocalizedPath("story", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.story" />
            </StyledLink>
            <StyledLink
              to={buildLocalizedPath("faq", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.faq" />
            </StyledLink>
            <StyledLink
              to={buildLocalizedPath("contact", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.contact" />
            </StyledLink>
          </StyledNavigation>
          <StyledLanguage>
            <LanguageSwitcher lang={locale} />
          </StyledLanguage>
          <MenuIcon onClick={() => setMobileMenuVisible(!mobileMenuVisible)}>
            <img src={Icon} height={26} alt="izbornik" />
          </MenuIcon>
          <MobileMenu isShown={mobileMenuVisible}>
            <StyledLink
              to={buildLocalizedPath("services", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.services" />
            </StyledLink>
            <StyledLink
              to={buildLocalizedPath("about", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.about" />
            </StyledLink>
            <StyledLink
              to={buildLocalizedPath("story", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.story" />
            </StyledLink>
            <StyledLink
              to={buildLocalizedPath("faq", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.faq" />
            </StyledLink>
            <StyledLink
              to={buildLocalizedPath("contact", locale)}
              partiallyActive={true}
            >
              <FormattedMessage id="header.nav.contact" />
            </StyledLink>
            <StyledLanguageMobile>
              <LanguageSwitcher lang={locale} />
            </StyledLanguageMobile>
          </MobileMenu>
        </StyledHeader>
      </Container>
    </StyledHeaderContainer>
  )
}

export default Header
