import React from 'react'
import DownIcon from '@assets/images/down.svg'
import { Container, Label, LangLink, Menu } from './style'

const langs = [
  { id: 'hr', label: 'Hrvatski', img: require('@assets/images/hr.png'), root: '/' },
  { id: 'en', label: 'English', img: require('@assets/images/en.png'), root: '/en' },
]

const LanguageSwitcher = ({ lang }) => {
  const currentLanguage = langs.filter(l => lang === l.id)[0]

  return (
    <Container>
      <Label>
        <div>
          <img src={currentLanguage.img} alt={currentLanguage.id} height={16} width={17} />
          <span>
            {currentLanguage.label}
          </span>
        </div>
        <img src={DownIcon} height={16} width={16} alt="" />
      </Label>
      <Menu>
        {
          langs
            .map(l => (
              <LangLink key={l.root} to={l.root} active={lang === l.id}>
                <img src={l.img} alt={l.label} height={16} width={17} />
                <span>
                  {l.label}
                </span>
              </LangLink>
            ))
        }
      </Menu>
    </Container>
  )
}

export default LanguageSwitcher
