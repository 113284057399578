import styled from "styled-components"
import { Link } from 'gatsby'
import { colors } from "@styles/theme"

const activeClassName = 'active-link'

export const StyledHeaderContainer = styled.header`
  border-bottom: 1px solid #E7ECF0;
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  background: #fff;
`

export const StyledHeader = styled.div`
  height: 80px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    height: 64px;
  }
`

export const StyledNavigation = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const LogoLink = styled(Link)`
  img {
    @media (max-width: 600px) {
      height: 24px;
      width: auto;
    }
  }
`

export const StyledLink = styled(Link).attrs({
  activeClassName: activeClassName
})`
  color: #333;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0 30px;
  text-decoration: none;
  position: relative;

  @media (max-width: 1024px) {
    font-size: 16px;
    margin-top: 32px;
  }

  &.${activeClassName} {
    &:after {
      content: '';
      width: calc(100% + 60px);
      position: absolute;
      bottom: -32px;
      left: -30px;
      height: 3px;
      background: ${colors.red};

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
`

export const StyledLanguage = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const StyledLanguageMobile = styled.nav`
  align-items: center;
  display: none;
  margin-top: 56px;

  @media (max-width: 1024px) {
    display: flex;
  }
`

export const MenuIcon = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
  }
`

export const MobileMenu = styled.div`
  position: fixed;
  height: calc(100% - 80px);
  top: 80px;
  max-width: 360px;
  width: 100%;
  background: #fff;
  z-index: 5;
  transition: 250ms cubic-bezier(0,1.08,.51,1) all;
  right: ${props => props.isShown ? '0px' : '-400px'};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    top: 64px;
    height: calc(100% - 64px);
  }
`