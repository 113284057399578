module.exports = {
  en: {
    index: "/en",
    about: "/en/about",
    services: "/en/services",
    faq: "/en/faq",
    contact: "/en/contact",
    story: "/en/our-story",
    // notfound: "/en/404",
  },
  hr: {
    index: "/",
    about: "/o-nama",
    services: "/usluge",
    faq: "/cesta-pitanja",
    contact: "/kontakt",
    story: "/nasa-prica",
    // notfound: "/404",
  },
}
