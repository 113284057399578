import styled from 'styled-components'
import { fonts, colors } from '@styles/theme'

export const StyledTitle = styled.h2`
  color: ${colors.red};
  font-family: ${fonts.alternative};
  font-size: 28px;
  text-align: ${props => props.center ? 'center' : 'left'};

  @media (max-width: 1024px) {
    font-size: 26px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
`