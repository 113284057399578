import React from 'react'
import PropTypes from 'prop-types'
import { StyledSection } from './style'

const Section = ({ children, theme }) => (
  <StyledSection theme={theme}>{children}</StyledSection>
)

Section.propTypes = {
  theme: PropTypes.oneOf(['transparent', 'gray', 'lightgray', 'blue']),
}

Section.defaultProps = {
  theme: 'transparent',
}

export default Section
