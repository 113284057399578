import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { fonts } from '@styles/theme'

export const StyledHeroContainer = styled.div`
  
`

export const StyledHero = styled(BackgroundImage)`
  height: 600px;
  padding-top: 45px;
  color: #fff;

  @media (max-width: 600px) {
    padding: 24px 15px 0;
    height: 300px;
  }

  h1 {
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;

    @media (max-width: 1024px) {
      font-size: 22px;
    }

    @media (max-width: 600px) {
      font-size: 14px;
    }

    span {
      font-family: ${fonts.alternative};
      font-size: 60px;
      font-weight: 700;
      line-height: 80px;
      text-transform: uppercase;
      display: block;

      @media (max-width: 1024px) {
        font-size: 50px;
        line-height: 1.5;
      }
  
      @media (max-width: 600px) {
        font-size: 30px;
        line-height: 1.3;
      }
    }
  }
`

// export const StyledNavigation = styled.nav`
//   display: flex;
//   align-items: center;

//   a {
//     color: #fff;
//     margin: 0 10px;
//     text-decoration: none;
//   }
// `