import styled from 'styled-components'

export const Service = styled.div`
  display: flex;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: 600px; 
    margin: 0 auto 30px;
  }

  @media (max-width: 1024px) {

  }
`

export const Content = styled.div`
  flex: 1;
  background: #F2F6F9;
  padding: 0 20px 20px 40px;

  @media (max-width: 1024px) {
    padding: 20px;
  }

  @media (max-width: 600px) {
    padding: 10px 15px;
  }
`

export const Text = styled.div`
  font-size: 14px;
  line-height: 22px;
`

export const Image = styled.div`
  flex: 1;

  @media (max-width: 1024px) {
    order: -1;
  }

  > div {
    min-height: 350px;
    max-height: 400px;
    height: 100%;
  }
`