import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, Section, Title } from '@components'
import { Content, Service } from './style'

const icons = [
  require('@assets/images/ico_servisiranje.svg'),
  require('@assets/images/ico_popravci.svg'),
  require('@assets/images/ico_tocnost.svg'),
  require('@assets/images/ico_kuciste.svg'),
  require('@assets/images/ico_vodonepropusnost.svg'),
  require('@assets/images/ico_remen.svg'),
  require('@assets/images/ico_baterija.svg'),

]

const HomeServices = () => (
  <Section>
    <Container small>
      <Title center>
        <FormattedMessage id="home.services.title" />
      </Title>
      <Content>
        {
          [1,2,3,4,5,6,7].map(i => (
            <Service key={`service-${i}`}>
              <img src={icons[i - 1]} alt="" height="60px" />
              <h3>
                <FormattedMessage id={`home.services.${i}.title`} />
              </h3>
              {
                i < 5 &&
                <p>
                  <FormattedMessage id={`home.services.${i}.description`} />
                </p>
              }
            </Service>
          ))
        }
      </Content>
    </Container>
  </Section>
)

export default HomeServices