import React from 'react'
import { StyledFooterContainer } from './style'

const Footer = () => {
  return (
    <StyledFooterContainer>
      &copy; {new Date().getFullYear()} Urarski servis Omega
    </StyledFooterContainer>
  );
}

export default Footer
