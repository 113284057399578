import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @media (max-width: 600px) {
    flex-direction: column;
    max-width: 300px;
    margin: 0 auto;
  }
`

export const Service = styled.div`
  flex: 1 1 220px;
  box-sizing: border-box;
  margin: 20px 10px;
  padding: 10px;
  text-align: center;
  max-width: 220px;

  @media (max-width: 600px) {
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin: 30px 0 0;
    text-align: left;
    flex: 0;
    max-width: unset;
  }

  img {
    @media (max-width: 600px) {
      height: 30px;
      margin-right: 12px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 400;

    @media (max-width: 600px) {
      font-size: 16px;
      margin: 0;
    }
  }

  p {
    display: none;
    font-size: 13px;
    line-height: 20px;
  }
`