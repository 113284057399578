import styled from 'styled-components'

export const Section = styled.section`
  padding: 40px 0;
`

export const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`

export const Text = styled.div`
  font-size: 13px;
  line-height: 20px;
`

export const ImageContainer = styled.div`
  flex: 1;
`

export const Images = styled.div`
  padding: 40px 0;
  margin: 0 -20px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    display: flex;
    flex-wrap: no-wrap;
  }

  > a {
    float: left;
    min-width: 200px;
    max-width: 200px !important;
    width: calc(25% - 40px);
    margin: 20px !important;
    flex: 1;
  }
`