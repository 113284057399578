import React from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import { graphql, useStaticQuery } from 'gatsby'
import { StyledHero, StyledHeroContainer } from './style'

const Hero = () => {
  const data = useStaticQuery(query)
  return (
    <StyledHeroContainer>
      <StyledHero
        Tag="div"
        fluid={data.desktop.childImageSharp.fluid}
        backgroundColor="#555"
      >
        <FormattedHTMLMessage tagName="h1" id="home.hero.title" />
      </StyledHero>
    </StyledHeroContainer>
  )
}

const query = graphql`
  query heroImageQuery {
    desktop: file(relativePath: { eq: "home_hero.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Hero
