import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  margin-bottom: 40px;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  p {
    font-size: 14px;
    line-height: 1.7;
  }
`

export const Left = styled.div`
  flex: 1;
  margin-right: 50px;

  @media (max-width: 800px) {
    margin: 0;
  }
`

export const Right = styled.div`
  flex: 1;

  @media (max-width: 800px) {
    margin-top: 30px;
    order: ${props => props.order ? 1 : -1};
  }
`