import styled from 'styled-components'
import { colors } from '@styles/theme'

export const StyledButton = styled.button`
  appearance: none;
  border: 0;
  outline: 0;
  font-size: 14px;
  background: ${colors.blue};
  border-radius: 4px;
  padding: 10px 20px;
  color: #fff;
`
