import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Section, Container, Title } from '@components'
import OmegaIcon from '@assets/images/logo_omega.svg'
import RadoIcon from '@assets/images/logo_rado.svg'
import LonginesIcon from '@assets/images/logo_longines.svg'
import TAGIcon from '@assets/images/logo_tagheuer.svg'
import { Content } from './style'

const Brands = () => (
  <Section>
    <Container small>
      <Title center>
        <FormattedMessage id="home.brands.title" />
      </Title>
      <Content>
        <img src={OmegaIcon} alt="Omega" />
        <img src={RadoIcon} alt="Rado" />
        <img src={LonginesIcon} alt="Longines" />
        <img src={TAGIcon} alt="TAGHeuer" />
      </Content>
    </Container>
  </Section>
)

export default Brands