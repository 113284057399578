import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  img {
    margin: 10px;

    @media (max-width: 1024px) {
      width: calc(50% - 20px);
      max-width: 220px;
    }
  }

`