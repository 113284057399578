import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'

const SEO = ({ locale, meta = {} }) => {
  const intl = useIntl()
  return (
    <>
      <Helmet title={intl.formatMessage({ id: meta.title })}>
        <html lang={locale} />
        <meta name="description" content={intl.formatMessage({ id: meta.description })} />
        <meta name="og:locale" content={locale} />
        <meta name="og:title" content={intl.formatMessage({ id: meta.title })} />
        <meta name="og:image" content={meta.image} />
      </Helmet>
    </>
  )
}

export default SEO
