import React from 'react'
import { useIntl } from 'react-intl'
import { Container, ResponsiveImage } from '@components'
import { Content, Img } from './style'
  
const Gallery = () => {
  const intl = useIntl()
  return (
    <Container>
      <Content>
        {
          [1,2,3,4,5,6,7,8].map(i => (
            <Img>
              <ResponsiveImage filename={`omega_g${i}.jpg`} alt={intl.formatMessage({ id: 'about.gallery.alt' })} />
            </Img>
          ))
        }
      </Content>
    </Container>
  )
}

export default Gallery
