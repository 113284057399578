import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Container, ResponsiveImage, Section, Title } from '@components'
import { C, Content, ImageContainer, Text } from './style'

const Strucnost = () => (
  <Section>
    <Container small>
      <C>
        <Content>
          <Title>
            <FormattedMessage id="about.strucnost.title" />
          </Title>
          <Text>
            <FormattedHTMLMessage id="about.strucnost.text" />
          </Text>
        </Content>
        <ImageContainer>
          <ResponsiveImage filename="about.jpg" />
        </ImageContainer>
      </C>
    </Container>
  </Section>
)

export default Strucnost