import styled from 'styled-components'

export const C = styled.div`
  display: flex;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const Content = styled.div`
  flex: 1;
  margin-right: 40px;

  @media (max-width: 800px) {
    margin: 0;
  }

  h2 {
    margin-top: 0;
  }
`

export const Text = styled.div`
  p {
    font-size: 13px;
    line-height: 24px;
  }
`

export const ImageContainer = styled.div`
  flex: 1;

  @media (max-width: 800px) {
    margin-top: 30px;
    order: 1;
  }
`