import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton } from './style'

const Button = ({ children }) => (
  <StyledButton>
    {children}
  </StyledButton>
)

Button.defaultProps = {
  size: 'sm',
  theme: 'info',
}

Button.propTypes = {
  size: PropTypes.string,
}

export default Button