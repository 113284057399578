import React from 'react'
import { IntlProvider } from 'react-intl'

import { Footer, Header } from '@components'
import { SEO } from '@utils'
import { GlobalStyle, Main } from './style'

const translations = {
  en: require(`../../translations/en.json`),
  hr: require(`../../translations/hr.json`),
}

const Page = ({ children, meta, locale }) => (
  <IntlProvider locale={locale} defaultLocale="en" messages={translations[locale]}>
    <GlobalStyle />
    <SEO
      locale={locale}
      meta={meta}
    />
    <Header locale={locale} />
    <Main>{children}</Main>
    <Footer />
  </IntlProvider>
)

export default Page
