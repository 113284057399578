import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Img = styled.div`
  width: 25%;

  img {
    display: block;
    max-width: 100%;
  }

  @media (max-width: 700px) {
    width: 50%;
  }
`