import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const ResponsiveImage = (props) => {
  const data = useStaticQuery(imagesQuery)
  const image = data.images.edges.find(n => {
    return n.node.relativePath === props.filename;
  });

  if (!image) {
    return null
  }

  if (props.cover) {
    return (
      <Img
        alt={props.alt}
        fluid={image.node.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        style={{ ...props.style, maxWidth: 1000, margin: 'auto' }}
      />
    )
  }

  return (
    <Img
      alt={props.alt}
      fluid={image.node.childImageSharp.fluid}
      style={{ ...props.style, maxWidth: 1000, margin: 'auto' }}
    />
  ) 
};

const imagesQuery = graphql`
  query {
    images: allFile(filter: {sourceInstanceName: { eq: "images" }}) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`

export default ResponsiveImage