import styled from 'styled-components'
import { colors } from '@styles/theme'

export const NotificationContainer = styled.section`
  background: ${colors.red};
  padding: 14px 0;
  color: #fff;

  @media (max-width: 600px) {
    padding: 14px 15px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
  }

  > div {
    font-size: 14px;

    @media (max-width: 600px) {
      font-size: 13px;
    }

    b {
      text-transform: uppercase;
    }

    &:last-of-type {
      font-size: 12px;
      color: #EDB3BD;
      width: 130px;
      
      @media (max-width: 600px) {
        margin-top: 10px;
        font-size: 11px;
      }
    }
  }
`