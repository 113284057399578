import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, Title } from '@components'
import { C, E, Section } from './style'

const images = [
  'ivan_puzak.png',
  'ivana_puzak_perikic.png',
  'bruno_jelencic.png',
]

const names = [
  'Ivan Puzak',
  'Ivana Puzak Perikić',
  'Bruno Jelenčić',
]

const Crew = () => (
  <Section>
    <Container small>
      <Title center>
        <FormattedMessage id="about.crew.title" />
      </Title>
      <C>
        {
          [1,2,3].map(e => (
            <E>
              <img src={require(`@assets/images/${images[e - 1]}`)} height={105} alt={names[e - 1]} />
              <div>
                <h3>
                  <FormattedMessage id={`about.crew.${e}.name`} />
                </h3>
                <p>
                  <FormattedMessage id={`about.crew.${e}.text`} />
                </p>
              </div>
            </E>
          ))
        }
      </C>
    </Container>
  </Section>
)

export default Crew