import styled from 'styled-components'
import { colors } from '@styles/theme'

const getThemeColor = theme => {
  switch (theme) {
    case 'gray':
      return colors.gray
    case 'lightgray':
      return colors.lightgray
    case 'blue':
      return colors.blue
    default:
      return 'transparent'
  }
}

export const StyledSection = styled.section`
  background: ${props => getThemeColor(props.theme)};
  padding: 40px 0 80px;

  @media (max-width: 600px) {
    padding: 20px 0 40px;
  }
`
