import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Section, Container, Title } from '@components'
import { QList } from './style' 

const Questions = () => (
  <Section>
    <Container small>
      <Title>
        <FormattedMessage id="faq.title" />
      </Title>
      <QList>
        {
          [1,2,3,4].map(q => (
            <li>
              <h3>
                <FormattedMessage id={`faq.${q}.title`} />
              </h3>
              <div>
                <FormattedHTMLMessage id={`faq.${q}.description`} />
              </div>
            </li>
          ))
        }
      </QList>
    </Container>
  </Section>
)

export default Questions