import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Container, ResponsiveImage, Title } from '@components'
import { Content, Left, Right } from './style' 

const Story = () => {
  const intl = useIntl()
  return (
    <Container small>
      <Content>
        <Left>
          <Title>
            <FormattedMessage id="story.title" />
          </Title>
          <p>
            <FormattedMessage id="story.p.1" />
          </p>
          <p>
            <FormattedMessage id="story.p.2" />
          </p>
          <p>
            <FormattedMessage id="story.p.3" />
          </p>
        </Left>
        <Right order style={{ maxWidth: '300px' }}>
          <ResponsiveImage filename="story_photo_01.jpg" alt={intl.formatMessage({ id: 'story.i.1' })} />
        </Right>
      </Content>
      <Content>
        <Left>
          <p>
            <FormattedMessage id="story.p.4" />
          </p>
          <p>
            <FormattedMessage id="story.p.5" />
          </p>
        </Left>
        <Right order>
          <ResponsiveImage filename="story_photo_02.jpg" alt={intl.formatMessage({ id: 'story.i.2' })} />
        </Right>
      </Content>
      <Content>
        <Left>
          <ResponsiveImage filename="story_photo_03.jpg" alt={intl.formatMessage({ id: 'story.i.3' })} />
        </Left>
        <Right order>
          <p>
            <FormattedMessage id="story.p.6" />
          </p>
          <p>
            <FormattedMessage id="story.p.7" />
          </p>
        </Right>
      </Content>
    </Container>
  )
}

export default Story
