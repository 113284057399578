import styled from 'styled-components'

export const StyledPartnersContainer = styled.section`
  padding: 100px 0;

  main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`
