import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Section, Container, Title, ResponsiveImage } from '@components'
import { Content, Service, Text, Image } from './style'

const Services = () => (
  <Section>
    <Container small>
      {
        [1,2,3,4,5].map(s => (
          <Service>
            <Content>
              <Title>
                <FormattedMessage id={`services.${s}.title`} />
              </Title>
              <Text>
                <FormattedHTMLMessage id={`services.${s}.text`} />
              </Text>
            </Content>
            {
              s < 5 &&
              <Image>
                <ResponsiveImage filename={`usluge_0${s}.jpg`} />
              </Image>
            }
          </Service>
        ))
      }
    </Container>
  </Section>
)

export default Services
