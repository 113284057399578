import React from 'react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import { Container, ResponsiveImage, Title } from '@components'
import { Content, Images, Section, Text } from './style'

const images = [
  require('@assets/images/cert/c_1.jpg'),
  require('@assets/images/cert/c_2.jpg'),
  require('@assets/images/cert/c_3.jpg'),
  require('@assets/images/cert/c_4.jpg'),
  require('@assets/images/cert/c_5.jpg'),
  require('@assets/images/cert/c_6.jpg'),
  require('@assets/images/cert/c_7.jpg'),
  require('@assets/images/cert/c_8.jpg'),
  require('@assets/images/cert/c_9.jpg'),
]

const Certificates = () => (
  <Section>
    <Container small>
      <Title center>
        <FormattedMessage id="about.certificates.title" />
      </Title>
      <Content>
        <Text>
          <FormattedHTMLMessage id="about.certificates.text" />
        </Text>
      </Content>
      <Images>
        {
          [1,2,3,4,5,6,7,8,9].map(c => <a target="_blank" rel="noopener noreferrer" href={images[c - 1]}><ResponsiveImage filename={`cert/c_${c}.jpg`} /></a>)
        }
      </Images>
    </Container>
  </Section>
)

export default Certificates