import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    font-family: Roboto, Tahoma, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #464646;
  }
`

export const Main = styled.main`
  min-height: calc(100vh - 120px - 80px);
  padding-top: 80px;

  @media (max-width: 600px) {
    padding-top: 64px;
  }
`
