import styled from 'styled-components'

export const QList = styled.ol`
  padding-left: 15px;

  li {
    margin-top: 24px;

    h3 {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
    }

    p {
      margin: 0;
      line-height: 24px;
    }
  }
`