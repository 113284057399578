import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Section, Container, ResponsiveImage } from '@components'
import IconLocation from '@assets/images/ico_location.svg'
import IconPhone from '@assets/images/ico_phone.svg'
import IconMail from '@assets/images/ico_mail.svg'
import IconTime from '@assets/images/ico_clock.svg'
import { Content, Entry, Image, Text } from './style'

const Contact = () => (
  <Section>
    <Container small>
      <Content>
        <Text>
          <Entry>
            <div>
              <img src={IconLocation} alt="" />
            </div>
            <div>
              <p>
                <FormattedMessage id="contact.location.name" />
              </p>
              <p>
                <FormattedMessage id="contact.location.address" />
              </p>
              <p>
                <FormattedMessage id="contact.location.city" />
              </p>
            </div>
          </Entry>
          <Entry>
            <div>
              <img src={IconPhone} alt="" />
            </div>
            <div>
              <p>
                <FormattedMessage id="contact.phone" />
              </p>
            </div>
          </Entry>
          <Entry>
            <div>
              <img src={IconMail} alt="" />
            </div>
            <div>
              <p>
                <FormattedMessage id="contact.email" />
              </p>
            </div>
          </Entry>
          <Entry>
            <div>
              <img src={IconTime} alt="" />
            </div>
            <div>
              <p>
                <FormattedMessage id="contact.hours.title" />
              </p>
              <p>
                <FormattedMessage id="contact.hours.customers" />
              </p>
              <p>
                <span><FormattedMessage id="contact.hours.1" /></span>
                <FormattedMessage id="contact.hours.2" />
              </p>
              <p>
                <span><FormattedMessage id="contact.hours.3" /></span>
                <FormattedMessage id="contact.hours.4" />
              </p>
              <p>
                <span><FormattedMessage id="contact.hours.5" /></span>
                <FormattedMessage id="contact.hours.6" />
              </p>
              <p>
                <span><FormattedMessage id="contact.hours.7" /></span>
                <FormattedMessage id="contact.hours.8" />
              </p>
            </div>
          </Entry>
        </Text>
        <Image>
          <ResponsiveImage filename="contact.jpg" />
        </Image>
      </Content>
    </Container>
  </Section>
)

export default Contact