import styled from 'styled-components'

export const Section = styled.section`
  padding: 0 0 40px;
`

export const C = styled.div`
  display: flex;
  align-items: stretch;
  margin: 0 -20px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0 15px;
  }
`
export const E = styled.div`
  margin: 90px 20px 20px;
  flex: 1;
  text-align: center;
  background: #F3F6F8;

  @media (max-width: 800px) {
    max-width: 400px;
    margin: 90px auto 20px;
  }

  > img {
    margin-top: -70px;
  }

  div {
    padding: 0 20px 20px;
    font-size: 13px;


    p {
      line-height: 20px;
    }
  }
`
