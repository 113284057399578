import styled from 'styled-components'
import { Link } from 'gatsby'

export const Container = styled.div`
  position: relative;
  font-size: 13px;
  font-weight: 700;
  width: 160px;

  :hover {
    > div {
      :first-of-type {
        // border-bottom-left-radius: 0;
        // border-bottom-right-radius: 0;
      }

      :last-of-type {
        display: block;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 200px;

    :hover {
      > div {
        :last-of-type {
          display: flex;
        }
      }
    }
  }
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  padding: 9px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #EAEAEA;

  & > div {
    width: 140px;
    display: flex;
    align-items: center;
  }

  span {
    font-size: 13px;
    margin-left: 8px;
  }

  @media (max-width: 1024px) {
    display: none;
  }

  img {
    border-radius: 50%;

    @media (max-width: 1024px) {
      height: 26px;
    }
  }
`

export const Menu = styled.div`
  position: absolute;
  width: calc(100% - 2px);
  top: 34px;
  display: none;
  padding: 9px 0;
  border: 1px solid #EAEAEA;
  background: #fff;

  @media (max-width: 1024px) {
    display: block;
    position: static;
    padding: 0;
    display: flex;
    border: 0;
  }
  
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 9px;
    box-sizing: border-box;
    color: inherit;

    span {
      margin-left: 8px;
    }

    @media (max-width: 1024px) {
      padding-left: 12px;
    }

    img {
      border-radius: 50%;
    }
  }
`

export const LangLink = styled(Link)`
  background: ${props => props.active ? '#DAF5F2' : 'transparent'};

  &:hover {
    background: ${props => props.active ? '#DAF5F2' : 'rgba(0,0,0,0.05)'};
  }

  @media (max-width: 1024px) {
    flex: 1;
    border: 1px solid ${props => props.active ? '#069697' : '#EAEAEA'};

    &:first-of-type {
      border-radius: 6px 0 0 6px;
    }

    &:last-of-type {
      border-radius: 0 6px 6px 0;
    }
  }
`
