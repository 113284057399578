import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  margin-top: 80px;
  margin-bottom: -80px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const Image = styled.div`
  flex: 1;

  @media (max-width: 800px) {
    order: -1;
  }
`
export const Text = styled.div`
  flex: 1;

  @media (max-width: 800px) {
    padding: 30px 20px;
  }
`

export const Entry = styled.div`
  display: flex;
  margin-bottom: 16px;

  > div {
    &:first-of-type {
      width: 40px;
    }
  }

  p {
    margin: 2px 0 8px;
    line-heiht: 20px;

    span {
      display: inline-block;
      width: 90px;
    }
  }
`
