import React from 'react'
import { Container, Section } from '@components'

const Map = () => {
  const link = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2780.9946229477605!2d15.971142416048421!3d45.811366079106534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d6fc8d4a9be5%3A0x460ad65a9aab74d4!2sOMEGA%20URARSKI%20SERVIS%20I%20TRGOVINA%2C%20VL.%20IVAN%20PUZAK!5e0!3m2!1sen!2shr!4v1585507460570!5m2!1sen!2shr`
  return (
    <Section>
      <Container small>
        <iframe
          aria-hidden="false"
          frameborder="0"
          height="450"
          src={link}
          style={{
            border: 0
          }}
          title="map"
          width="100%"
        />
      </Container>
    </Section>
  )
}

export default Map