import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Container } from '@components'
import { csvJSON } from './utils'
import { Content, NotificationContainer } from './style'
const fileUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSPVbHtvnx6-FxUzoJGbrACOQRxjtwHMbrroBm1bIFj9dLa76m5JL3YdGGHJCng-t8kadLXp3iMgnOU/pub?gid=0&single=true&output=csv'

const parseData = (d) => {
  const message = csvJSON(d)[0]
  return message
}

const Notification = ({ locale }) => {
  const [message, setMessage] = useState(undefined)
  useEffect(() => {
    if (!message) {
      fetch(fileUrl)
        .then(res => res.text())
        .then(data => {
          setMessage(parseData(data))
        })
        .catch(err => console.log(err))
    }
  },)

  if (!message) {
    return null
  }

  if (message.Objavljeno === 'FALSE') {
    return null
  }

  const text = locale === 'hr' ? message.HR : message.EN
  debugger

  return (
    <NotificationContainer>
      <Container small>
        <Content>
          <div>
            <b>
              <FormattedMessage id="Obavijest" />: &nbsp;
            </b>
            {text}
          </div>
          <div>
          <FormattedMessage id="Objavljeno" />: {message.Datum}
          </div>
        </Content>
      </Container>
    </NotificationContainer>
  )
}

export default Notification